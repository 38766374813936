import React, { useState } from 'react'
import { Badge, Box, Token } from '@revolut/ui-kit'
import {
  jobDescriptionFormRequest,
  useGetJobPosting,
  useGetJobPostings,
} from '@src/api/jobPosting'
import { getBackUrl } from '@src/features/JobPostingFlow/utils'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/features/JobPostingFlow/types'
import { JobPostingInterface, LocationSource } from '@src/interfaces/jobPosting'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { Route, Switch, useLocation, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Form from '@src/features/Form/Form'
import PageLoading from '@src/components/PageLoading/PageLoading'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Details } from '@src/features/JobPostingFlow/Details'
import { useGetSpecialisation } from '@src/api/specialisations'
import { useGetRequisitionRecruiter } from '@src/api/recruitmentGroups'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { ApplicationForm } from '@src/features/JobPostingFlow/ApplicationForm'
import { HiringProcess } from '@src/features/JobPostingFlow/HiringProcess'

const useJobPosting = () => {
  const { initialValues, values } = useLapeContext<JobPostingInterface>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const { data: jobPostings, isLoading: isLoadingJobPostings } = useGetJobPostings(
    locationState?.specialisationId
      ? [
          {
            columnName: 'specialisation__id',
            filters: [
              {
                id: locationState?.specialisationId,
                name: String(locationState?.specialisationId),
              },
            ],
          },
        ]
      : [],
    !!locationState?.specialisationId,
  )
  const generatedJobPostingId = (jobPostings || []).find(d => d.is_generated)?.id
  const { data: jobPosting, isLoading: isLoadingJobPosting } =
    useGetJobPosting(generatedJobPostingId)
  if (jobPosting?.name && !initialValues.name && !values.name) {
    values.name = jobPosting.name
  }
  if (!values.location_source) {
    values.location_source = LocationSource.requisition
    values.locations = undefined
  }
  if (!isLoadingJobPosting && !values.sections) {
    values.sections = jobPosting?.sections ?? [
      {
        title: 'About the role',
        content: '',
      },
      {
        title: 'What you’ll be doing',
        content: '',
      },
      {
        title: "What you'll need",
        content: '',
      },
      {
        title: 'Nice to have',
        content: '',
      },
    ]
  }
  return {
    isLoading: isLoadingJobPostings || isLoadingJobPosting,
  }
}

const useSpecialisation = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const { data, isLoading } = useGetSpecialisation(locationState?.specialisationId)
  if (!values.specialisation && data) {
    values.specialisation = {
      id: data.id,
      name: data.name,
      role_id: data.role.id,
      status: data.status,
    }
  }
  if (!values.title && data) {
    values.title = data.name
  }
  return {
    isLoading,
  }
}

const useRecruiter = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const { data, isLoading } = useGetRequisitionRecruiter(locationState?.specialisationId)
  if (!values.recruiter && data) {
    values.recruiter = data.recruiter
  }
  return {
    isLoading,
  }
}

interface JobPostingFlowTabs extends TabBarTableNavigationInterface {
  component: React.ElementType
}

type JobPostingFlowBodyProps = {
  tabs: JobPostingFlowTabs[]
  onAfterSubmit: (n: number) => void
}

const JobPostingFlowBody = ({ tabs, onAfterSubmit }: JobPostingFlowBodyProps) => {
  const { isLoading: loadingJobPosting } = useJobPosting()
  const { isLoading: loadingSpecialisation } = useSpecialisation()
  const { isLoading: loadingRecruiter } = useRecruiter()
  if (loadingJobPosting || loadingSpecialisation || loadingRecruiter) {
    return <PageLoading />
  }
  return (
    <Switch>
      {tabs.map((tab, index) => {
        const path = tab.path as string
        return (
          <Route exact path={path} key={path}>
            <tab.component
              onAfterSubmit={() => {
                onAfterSubmit(index)
              }}
            />
          </Route>
        )
      })}
    </Switch>
  )
}

const JobPostingFlowForm = (props: JobPostingFlowBodyProps) => {
  const params = useParams<JobPostingFlowParams>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const id = params?.id || locationState?.id
  return (
    <Form
      api={jobDescriptionFormRequest}
      validator={{}}
      disableLocalStorageCaching
      forceParams={id ? { id } : undefined}
    >
      <JobPostingFlowBody {...props} />
    </Form>
  )
}

export const JobPostingFlow = () => {
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const params = useParams<JobPostingFlowParams>()
  const isEdit = !!params?.id
  const tabs = [
    {
      title: 'Job details',
      path: ROUTES.FORMS.JOB_POSTING_FLOW.DETAILS,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.DETAILS, params),
      component: Details,
      canView: true,
    },
    {
      title: 'Application form',
      path: ROUTES.FORMS.JOB_POSTING_FLOW.APPLICATION_FORM,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.APPLICATION_FORM, params),
      component: ApplicationForm,
    },
    {
      title: 'Hiring process',
      path: ROUTES.FORMS.JOB_POSTING_FLOW.HIRING_STAGES,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.HIRING_STAGES, params),
      component: HiringProcess,
    },
  ]
  const [lastCompletedIndex, setLastCompletedIndex] = useState<number>(
    isEdit ? tabs.length : -1,
  )
  const filteredTabs = tabs.map((tab, index) => {
    const complete = index <= lastCompletedIndex
    const disabled = index > lastCompletedIndex + 1
    return {
      ...tab,
      disabled,
      preTitle: (
        <Box mr="s-8">
          <Badge size={16} bg={complete ? Token.color.green : Token.color.foreground}>
            {index + 1}
          </Badge>
        </Box>
      ),
    }
  })
  return (
    <PageWrapper>
      <PageHeader
        title={isEdit ? 'Edit job posting' : 'New job posting'}
        subtitle="Configure Job Description,  Hiring Process"
        backUrl={getBackUrl(params, locationState)}
      >
        <Box pb="s-8" maxWidth="100vw">
          <TabBarNavigation tabs={filteredTabs} />
        </Box>
      </PageHeader>
      <JobPostingFlowForm
        tabs={filteredTabs}
        onAfterSubmit={n => {
          if (n > lastCompletedIndex) {
            setLastCompletedIndex(n)
          }
        }}
      />
    </PageWrapper>
  )
}
