import React, { useEffect, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface, GoalKpiDetails } from '@src/interfaces/goals'
import { KpiInterface, KpiTargets } from '@src/interfaces/kpis'
import { IdAndName } from '@src/interfaces'
import { CellWithItem } from '../../../common/CellWithItem'
import AddGoalTargetForm from '../../SidebarForms/AddGoalTargetForm'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { ActionButton } from '@revolut/ui-kit'

import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { TargetCell } from './TargetCell'
import omit from 'lodash/omit'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import pick from 'lodash/pick'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useFormObserver } from '../FormObserverProvider'
import { isEqual } from 'lodash'

interface TargetsWidgetProps {
  reviewCycle: ReviewCyclesInterface
}

const goalTargetToKpi = (target: GoalKpiDetails): KpiInterface => ({
  ...target,
  status: target.status?.id,
})

export const TargetsWidget = ({ reviewCycle }: TargetsWidgetProps) => {
  const { values } = useLapeContext<GoalsInterface>()
  const { getFormById } = useFormObserver()
  const { data: reviewCycles } = useGetSelectors(selectorKeys.review_cycles)
  const { data: performanceSettings } = useGetPerformanceSettings()
  const isMultipleGoalsTargetsEnabled =
    performanceSettings?.enable_multiple_goal_targets_per_cycle

  const { getEntityProps } = useOrgEntity()

  const [expandedIndex, setExpandedIndex] = useState<number>()

  useEffect(() => {
    // we want to collapse just submitted target
    // btoa will be different because id value will be updated
    // HEAD UP: will be also triggered by sorting but so far sorting is not supported by this table

    if (expandedIndex) {
      setExpandedIndex(undefined)
    }
    // btoa is deprecated only for NODE, doesn't have alternative in browser
  }, [
    btoa(
      values.kpis
        .map(kpi => kpi.id)
        .filter(Boolean)
        .join(','),
    ),
  ])

  const emptyTarget = {
    ...getEntityProps(),
    tempId: Math.ceil(Math.random() * 10e6),
    owner: values.owner as EmployeeOptionInterface & { team: IdAndName },
    goal: { id: values.id },
    targets: [{ review_cycle: reviewCycle }],
    update_type: undefined,
  } as unknown as GoalKpiDetails & { tempId?: number }

  useEffect(() => {
    if (values.kpis.length === 0) {
      values.kpis.push(emptyTarget)
    }
    setExpandedIndex(values.kpis.length - 1)
  }, [values.kpis.length])

  const onCopy = (copyFromTarget?: Partial<GoalKpiDetails & { tempId?: number }>) => {
    const copyForm = getFormById(copyFromTarget?.tempId)?.().form.values
    const base = copyForm ? omit(copyForm, 'id', 'targets', 'target_epics') : {}
    const target = copyForm?.targets?.[0]
    const currentCycle = target?.review_cycle

    const nextCycle = currentCycle
      ? reviewCycles?.find(c => Number(c.offset) === Number(currentCycle.offset) - 1)
      : reviewCycle

    values.kpis.push({
      ...emptyTarget,
      ...base,
      tempId: Math.ceil(Math.random() * 10e6),
      targets: [
        {
          ...pick(target, 'kpi_goal'),
          review_cycle: nextCycle || currentCycle || reviewCycle,
          initial_value: copyForm?.targets?.[0].target,
        } as Partial<KpiTargets>,
      ],
    } as unknown as GoalKpiDetails & { tempId?: number })
  }

  const onAddClicked = async () => {
    if (isMultipleGoalsTargetsEnabled) {
      values.kpis.push(emptyTarget)
    } else if (values.kpis.length) {
      onCopy(values.kpis[values.kpis.length - 1])
    }
  }

  const renderCards = (cards: GoalKpiDetails[]) => {
    return cards.map((target, index) => {
      const formValues = getFormById(target.id || target.tempId)?.().form.values
      // we need to update the main form to store cached values for metrics
      if (formValues && !isEqual(formValues, values.kpis[index])) {
        if (formValues.name) {
          values.kpis[index].name = formValues.name
        }
        if (formValues.unit) {
          values.kpis[index].unit = formValues.unit
        }
        if (formValues.update_type) {
          values.kpis[index].update_type = formValues.update_type
        }
        if (formValues.targets) {
          values.kpis[index].targets = formValues.targets
        }
      }
      return (
        <TargetCell
          allowDelete={values.kpis.length > 1}
          target={formValues || target}
          index={index + 1}
          key={target.id || target.tempId}
          expanded={expandedIndex === index}
          onToggle={() => {
            return expandedIndex === index
              ? setExpandedIndex(undefined)
              : setExpandedIndex(index)
          }}
          onCopy={isMultipleGoalsTargetsEnabled ? onCopy : undefined}
        >
          <AddGoalTargetForm initialValues={formValues || goalTargetToKpi(target)} />
        </TargetCell>
      )
    })
  }

  return (
    <CellWithItem
      icon="TurboTransfer"
      title="Metric"
      description="What are the metrics and success criteria?"
    >
      {renderCards(values.kpis)}

      {values.update_type?.id === 'target_based' ? (
        <ActionButton useIcon="Plus" onClick={() => onAddClicked()}>
          Add metric
        </ActionButton>
      ) : null}
    </CellWithItem>
  )
}
